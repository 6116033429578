div.dropdown-inner{
  padding: 0!important;
}
div.dropdown-inner > .dropdown-toggle{
  padding: 9.5px 25px 9.5px 10px !important;
  border: none!important;
}
div.dropdown-inner > .dropdown-menu{
  border: none!important;
}
div.dropdown-inner > .dropdown-menu > .dropdown-item:first-child{
  border-top: 1px solid #ccc!important;
}
.header_area > .float-left > button{
  background-color: transparent;
}
.header_area .quickmenu_area .btn_select+.dropdown-menu .dropdown-submenu-inner>.dropdown-submenu-item, .header_area .user_area .btn_select+.dropdown-menu .dropdown-submenu-inner>.dropdown-submenu-item{
  border-top: none;
}
.header_area .user_area span{
  padding-left: 4px;
}
.dropdown-toggle{
  cursor: pointer;
}
.dropdown-menu{
  border: 1px solid #ccc;
}
.sidebar_area .sidebar_list li span{
  font-size: 16px;
}
.header_area .quickmenu_area .btn_select+.dropdown-menu .dropdown-item, .header_area .user_area .btn_select+.dropdown-menu .dropdown-item{
  color: #999999;
  font-size: 14px;
}
.header_area .user_area .btn_select+.dropdown-menu .dropdown-item > i,.header_area .user_area .btn_select+.dropdown-menu .dropdown-item > span{
  pointer-events: none;
}
.work_area .th_style{
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  color: #4f86f2;
}
.work_area .th_style span{
  color: #4f86f2;
  position: relative;
}
.work_area .th_style span > span.th_sort:before{
  font-family: 'fcs';
  content: '\e90c';
  top: -8px;
  left: 3px;
  position: absolute;
  opacity: .3;
}
.work_area .th_style span > span.th_sort:after{
  font-family: 'fcs';
  content: '\e90a';
  bottom: -8px;
  left: 3px;
  position: absolute;
  opacity: .3;
}
.work_area .th_style span > span.th_sort.sort_asc:before{
  opacity: 1;
  color: #4f86f2;
}
.work_area .th_style span > span.th_sort.sort_desc:after{
  opacity: 1;
  color: #4f86f2;
}
.work_area table tr th:first-child,.work_area table tr td:first-child{
  text-align: left;
}
.work_area.text_center table tr th:first-child,.work_area.text_center table tr td:first-child{
  text-align: center;
}

/* for dropdown menu */
.dropdown-factory{
	position: relative;
	cursor: pointer;
}

.factory-toggle{
	min-width: 88.08px;
	height: 40px;
}

.factory-toggle a{
	line-height: 30px;
	font-size: 14px;
	color: #9c9eb0 !important;
	cursor: pointer;
}

.factory-menu{
	background-color: #fff;
	color: #999; 
	/* height: 120px; */
	width: 162px;
	position: absolute; 
	top: 50px; 
	left: -10px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: none;
	font-size: 14px;
}

.factory-item{
	height: 40px;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.machine-menu{
	/* height: 40px; */
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.machine-menu > div{
	height: 34px;
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}
.analysis_area .analysis_amount_inner .list-group-item {
  background-color: #fff;
  cursor: pointer;
}
.analysis_area .analysis_amount_inner .list-group-item + .list-group-item{
  margin-top: 5px;
}
.analysis_area .analysis_amount_inner .list-group-item:hover{
  background-color: #e0e1e4;
}
.analysis_area .analysis_amount_inner .list-group-item.checked{
  background-color: #eeeeee;
}
.analysis_area .analysis_amount_inner .list-group-item .form-check{
  padding-left: 0.85rem;
  pointer-events: none;
}
.analysis_area .analysis_amount_inner .list-group-item .form-check-label{
  margin-left: 0.4rem;
}
.pes_production_finish .pes_production_btn .btn_finish{
  background-color: #B1B4CA;
}
.pes_production_finish .pes_production_btn .btn_finish:hover{
  background-color: #B1B4CA;
}
.pes_production_same .media-image{
  max-width: 20%;
}
.pes_production_same .media-image img{
  width:100%;
}