.fc-icon.icon-settings,
.fc-icon.icon-cloud_uploadbackup {
  font-family: "fcs" !important;
}
.fc {
  height: 100%;
}
.workday1.fc-daygrid-event .fc-event-time,
.workday1.fc-daygrid-event .fc-event-title,
.workday2.fc-daygrid-event .fc-event-time,
.workday2.fc-daygrid-event .fc-event-title,
.workday3.fc-daygrid-event .fc-event-time,
.workday3.fc-daygrid-event .fc-event-title,
.workday4.fc-daygrid-event .fc-event-time,
.workday4.fc-daygrid-event .fc-event-title {
  color: #656b82;
}

.overtime.fc-daygrid-event .fc-event-time,
.overtime.fc-daygrid-event .fc-event-title {
  color: #1890ff;
}

.maintain.fc-daygrid-event .fc-event-time,
.maintain.fc-daygrid-event .fc-event-title {
  color: #ff8000;
}

.workday1 .fc-event-time::before {
  content: "❶";
  font-size: 1rem;
  padding-right: 3px;
}

.workday2 .fc-event-time::before {
  content: "❷";
  font-size: 1rem;
  padding-right: 2px;
}

.workday3 .fc-event-time::before {
  content: "❸";
  font-size: 1rem;
  padding-right: 2px;
}

.workday4 .fc-event-time::before {
  content: "❹";
  font-size: 1rem;
  padding-right: 2px;
}

.overtime .fc-event-time::before {
  content: "●";
  font-size: 1.4rem;
  padding-left: 1px;
  padding-right: 4px;
}

.maintain .fc-event-time::before {
  content: "🛠";
  font-size: 1rem;
  padding-right: 2px;
}
