.react-bootstrap-daterangepicker-container {
  height: 100%;
}
.picker,
.picker:active,
.picker:focus {
  background: linear-gradient(to bottom, #fefeff 0%, #efeff6 100%);
  border: 1px solid #c8c5d5;
  color: #484067;
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 100%;
}
.picker > span {
  margin-right: 10px;
}
.picker-small {
  background: linear-gradient(to bottom, #fefeff 0%, #efeff6 100%);
  border: 1px solid #c8c5d5;
  color: #484067;
  cursor: pointer;
  padding: 7.5px;
  font-weight: bold;
}

.date-radio > div {
  border: 1px solid #c8c5d5;
  border-right: none;
}
.date-radio > div:last-child {
  border-right: 1px solid #c8c5d5;
}
.date-radio label {
  background: linear-gradient(to bottom, #fefeff 0%, #efeff6 100%);
  cursor: pointer;
  padding: 10px 30px;
  font-weight: bold;
  margin-bottom: 0;
  color: #75498d;
}
.date-radio input:checked + label {
  background: linear-gradient(
    to bottom,
    #c3c2ce 0%,
    #d4d2e0 1%,
    #dfdce8 2%,
    #dfdce8 100%
  );
}

.custom-radio-ipt,
.date-radio input {
  display: none;
}
.custom-radio-label {
  color: #808080;
  cursor: pointer;
  background-color: #efefef;
  border-radius: 5px;
  font-size: 18px;
  padding: 20px 10px;
  width: 100%;
  margin-bottom: 0;
}
.custom-radio-label:hover {
  background-color: #d3d2d2;
}
.custom-radio-ipt:checked + .custom-radio-label {
  background-color: #2e355b;
  color: white;
}
.pagination-area > *,
.pagination-area > *:active,
.pagination-area > *:focus {
  color: #646464;
  padding: 5px 15px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: pointer;
}
.pagination-area > button:hover,
.pagination-area > button:disabled {
  background-color: #f0f0f0;
}
