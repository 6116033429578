.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.5);
    color: rgb(202, 255, 195);
    position: absolute;
    top: 60px;
}

@keyframes marquee {
    0% {
    transform: translate(-100%, 0);
    animation-timing-function: ease-in;
}
    100% {
        transform: translate(0, 0);
        animation-timing-function: ease-out;
    }
}